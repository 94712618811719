import React, { useState, useEffect } from 'react';
import './footer.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Media from 'react-bootstrap/Media';
import axios from 'axios';
import DOMPurify from 'dompurify';
import Homebtnpdf from './Homebtnpdf';
import Facebook from "./assets/facebook.svg";
import LinkedIn from "./assets/linkedin.svg";
import youtube from "./assets/youtube.svg";
import instagram from "./assets/instagram.svg";
 
 



export default function Footer() {

    const [data, setData] = useState();
    const [dataInformation, setDataInformation] = useState();
    const [dataSocialMedia, setDataSocialMedia] = useState();

    const getFooter = () => {

        let prefix = "AF5F44F6-F7A3-4F55-AD58-3CA7D7A947A7";
        axios.get(`${process.env.REACT_APP_API_PROXY}/api/Tree/EXTERNAL_GetTree/${prefix}`).then(result => {

            let json = result.data[0].data;
            if (json) {
                setData(json);
            }

        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

    const getFooterInformation = () => {

        let prefix = "44A8274F-C85A-40FF-ACB4-96E1CD6D30E0";
        axios.get(`${process.env.REACT_APP_API_PROXY}/api/Tree/EXTERNAL_GetTree/${prefix}`).then(result => {

            let json = result.data[0].data;
            if (json) {
                setDataInformation(json);
            }

        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

    const getFooterSocialMedia = () => {

        let prefix = "54DC1CFD-C8F0-42BC-8C17-F4B6E2055AE2";
        axios.get(`${process.env.REACT_APP_API_PROXY}/api/Tree/EXTERNAL_GetTree/${prefix}`).then(result => {

            let json = result.data[0].data;
            if (json) {
                setDataSocialMedia(json);
            }

        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

    useEffect(() => {

        getFooter();
        getFooterInformation();
        getFooterSocialMedia();

    }, []);

    const BuildList = ({ data }) => {

        return (
            <ul>
                {
                    data.node &&
                    data.node.map(item => (
                        <li key={item.id}>
                            {
                                item.node_link ?
                                    <Link to={item.node_link}>{item.node_title}</Link>
                                    :
                                    <h3>{item.node_title}</h3>
                            }

                            {
                                item.node &&
                                <BuildList data={item} />
                            }
                        </li>
                    ))
                }
            </ul>
        )
    }


    return (
        <>
            {
                data &&
                <footer>
                    <div className="container-app footer">
                    
                        <Col sm="12">
                           {/* <Row className="footer-copyright">
                                < Homebtnpdf />
                            </Row>*/}

                            <Row>

                            </Row>
                            <Row>
                                <Col lg="auto" className="footer-info">
                                    <img src="/media/icon/logo-2021.png" alt="CASH Assurances, Algérie." />
                                    {
                                        dataInformation &&
                                        dataInformation.map(item => (
                                            <Media key={item.id} className="mb-3">
                                                <Media.Body>
                                                    <h5 dangerouslySetInnerHTML={{ __html: item.node_HTMLtitle }}></h5>
                                                </Media.Body>
                                            </Media>
                                        ))
                                    }
{/*
                                    {
                                        dataSocialMedia &&
                                        <div className="footer-social-media">
                                            {
                                                dataSocialMedia.map(item => (
                                                    <a key={item.id}
                                                        href={item.node_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <img src={item.node_icon} alt={item.node_title} />
                                                    </a>
                                                ))
                                            }
                                        </div>
                                    }
                                */}
                                <div className="footer-social-media">
                                        <a href="https://www.facebook.com/CASHASSURANC" target="_blank" rel="noopener noreferrer">
                                            <img src={Facebook} alt="Facebook" />
                                            <p>Facebook</p>
                                        </a>
                                                                         
                                        <a href="https://www.linkedin.com/company/cash-assurances" target="_blank" rel="noopener noreferrer">
                                            <img src={LinkedIn} alt="LinkedIn" />
                                            <p>LinkedIn</p>
                                        </a>
                                        <a href="https://www.youtube.com/channel/UCLGHfcwP6YOQsxMov2IZhdw" target="_blank" rel="noopener noreferrer">
                                            <img src={youtube} alt="youtube" />
                                            <p>youtube</p>
                                        </a>
                                        <a href=" https://www.instagram.com/cash_assurances">
                                            <img src={instagram} alt="instagram" />
                                            <p>instagram</p>
                                        </a>
                                        

                                       </div>
                                </Col>
                                <Col className="footer-lists">
                                    <Row className="justify-content-between">
                                        {
                                            data &&
                                            data.map((element, index) => (
                                                <Col key={element.id} lg="auto" className="footer-segment">
                                                    <h3>{element.node_title}</h3>
                                                    <BuildList data={element} />
                                                </Col>
                                               
                                            ))
                                        }
                                        <Col className="footer-lists">
                                        < Homebtnpdf />
                                        </Col>
 
                                           

                                    </Row>
                                </Col>
                            </Row>
                            <Row className="footer-copyright">
                                <p>@2024 Tous droits réservés par cash-assurances.dz</p>
                            </Row>
                        </Col>
                    </div>

                </footer>

            }
        </>
    )
}