import React, { useState } from 'react';
import Instructions_02_03_CTRF from "./Instructions_02_03_CTRF.pdf";
import Devoir_Vigilance from "./Devoir_Vigilance.pdf";
import Obligations_Assujetis_Assurance from "./Obligations_Assujetis_Assurance.pdf";
import Button from "react-bootstrap/Button";

function Homebtnpdf() {
  // Declare state for tracking hover status
  const [hovered_CASH, setHovered_CASH] = useState(false);

  // Functions to handle mouse enter and leave events
  const handleMouseEnter = () => setHovered_CASH(true);
  const handleMouseLeave = () => setHovered_CASH(false);

  return (
    <div style={{ display:"bloc",marginLeft:"30%",  gap: "15px", paddingBottom: "10px"}}>
       <h6>
        Documents
       </h6>
        <a
          href={Instructions_02_03_CTRF}
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#000000",
            fontSize: "14px",
            textDecoration: "none",
          }}
        >
          Instructions CTRF
          <br>
          </br>
        </a>
            
        <a
          href={Devoir_Vigilance}
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#000000",
            fontSize: "14px",
            textDecoration: "none",
          }}
        >
          Devoir Vigilance
          <br>
          </br>
        </a>
          
        <a
          href={Obligations_Assujetis_Assurance}
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#000000",
            fontSize: "14px",
            textDecoration: "none",
          }}
        >
          Obligations Assujetis Assurance
        </a>
      
    </div>
  );
}

export default Homebtnpdf;

